<template>
  <transition name="fade">
    <div :key="index" class="answersList container">
      <div class="row mb-4">
        <div
          class="col-sm mb-4"
          v-for="answer in questionList[index].answers"
          v-bind:key="answer"
        >
          <input
            type="radio"
            v-bind:value="answer"
            v-bind:name="questionList[index]"
            v-model="picked"
            :id="'answerInput' + answer.id"
          />
          <label :for="'answerInput' + answer.id">{{ answer.text }} </label>
        </div>
      </div>
      <button @click="answerClicked(picked)" class="btn btn-primary">
        VALIDER
      </button>
    </div>
  </transition>
</template>

<script>
// 1 - importation tag_commander_wrapper
// import TC_Wrapper from "vue-tag-commander";

export default {
  name: "answer",
  props: ["questionList", "index"],
  data() {
    return {
      picked: "",
      pickedVar: "",
    };
  },
  methods: {
    /* Au click sur le bouton, on lance la méthode answerClicked qui déclenche l'événement "answerCheck" dans Quiz.vue après vérification que la réponse n'est pas vide */
    answerClicked(answer) {
      this.pickedVar = answer;
      if (this.pickedVar !== "" && this.pickedVar !== undefined) {
        this.$emit("answerPicked", answer);
        // 2 - conteneur Tagcommander pour la réponse
        // todo: inclure la réponse choisie dans les tags
        // const wrapper = TC_Wrapper.getInstance();
        // wrapper.setDebug(true);
        // wrapper.setTcVars(
        //   this.questionList[this.index].answers[this.index].tagcommander
        // );
        // wrapper.setTcVars(
        //   this.questionList[this.index].answers[this.index].text
        // );
      }
      this.pickedVar = "";
      this.picked = "";
    },
  },
};
</script>

<style scoped>
.answersList {
  margin: 3rem 0;
}

/*
.answersList .row {
  transition: all 1s;
}
*/

.answersList .row > div {
  display: flex;
  align-items: center;
}

label {
  display: block;
  font-size: 2rem;
  font-weight: normal;
  color: #006a4e;
  cursor: pointer;
  margin: 0;
  padding-left: 2rem;
  text-align: left;
}

input[type="radio"] {
  cursor: pointer;
}

.fade-enter-active {
  transition: all 1.5s ease;
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}

.fade-leave-active {
  transition: all 0.3s ease;
  display: none;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .answersList .row > div {
    display: block;
  }

  label {
    margin: 0;
    padding-left: 0;
    text-align: center;
  }
}
</style>
